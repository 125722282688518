import moment from "moment";
import invariant from "ts-invariant";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Form, message } from "antd";
import { useCallback, useEffect, useState } from "react";

import sdk from "@/sdk";

export function useEditUser({
  selectedUserId,
  getUsers,
}: {
  selectedUserId?: string;
  getUsers?: () => void;
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const editUser = useCallback(
    (values) => {
      setLoading(true);
      if (selectedUserId) {
        if (values.password) {
          if (values.password !== values.verifyPassword) {
            message.warn("Нууц үг баталгаажуулах буруу байна.");
            setLoading(false);
            return;
          }
        }

        const birthDate = new Date(values.birthDate).getTime();
        sdk
          .editUser({
            _id: selectedUserId,
            user: {
              avatar: values.avatar,
              email: values.email,
              firstName: values.firstName,
              lastName: values.lastName,
              password: values.password,
              phoneNo: values.phoneNo,
              role: values.role,
              type: values.type,
              birthDate: Number.isNaN(birthDate) ? null : birthDate,
              bankInfo:
                values.bankName || values.accountNo || values.accountName
                  ? {
                      bankName: values.bankName,
                      accountNo: values.accountNo,
                      accountName: values.accountName,
                    }
                  : null,
            },
          })
          .then(() => {
            getUsers?.();
            message.success("Амжилттай засагдлаа", 2);
            setLoading(false);
          })
          .catch((err) => {
            message.warn(err.response?.errors[0]?.message, 2);
            setLoading(false);
          })
          .finally(() => setLoading(false));
      }
    },
    [getUsers, selectedUserId]
  );

  const { data, error, isLoading } = useQuery({
    queryKey: ["user", selectedUserId],
    queryFn: () => {
      invariant(selectedUserId, "selectedUserId must be defined");
      return sdk.getUser({ _id: selectedUserId });
    },
    select: (data) => data.getUser,
    enabled: !!selectedUserId,
  });
  const createMSCDMutation = useMutation({
    mutationFn: (id: string) => sdk.cereateMSCDAccount({ userId: id }),
    onSuccess: () => {
      message.success("Хүсэлт ажмиллтай илгээлээ.", 2);
    },
    onError: (err: any) => message.warn(err.response?.errors[0]?.message, 2),
  });
  useEffect(() => {
    if (error) {
      message.warn((error as unknown as Error).message);
    }
  }, [error]);
  useEffect(() => {
    if (!data) {
      return;
    }
    form.setFields([
      {
        name: "lastName",
        value: data.lastName,
      },
      {
        name: "firstName",
        value: data.firstName,
      },
      {
        name: "email",
        value: data.email,
      },
      {
        name: "phoneNo",
        value: data.phoneNo,
      },
      {
        name: "role",
        value: data.role,
      },
      {
        name: "type",
        value: data.type,
      },
      {
        name: "birthDate",
        value: data.birthDate ? moment(new Date(data?.birthDate)) : "",
      },
      {
        name: "kycInfo",
        value: data.kycInfo.status,
      },
      {
        name: "bankName",
        value: data.bankInfo?.bankName,
      },
      {
        name: "accountNo",
        value: data.bankInfo?.accountNo,
      },
      {
        name: "accountName",
        value: data.bankInfo?.accountName,
      },
    ]);
  }, [data, form]);

  const addUser = useCallback(
    (values) => {
      setLoading(true);
      if (values.password) {
        if (values.password !== values.verifyPassword) {
          message.warn("Нууц үг баталгаажуулах буруу байна.");
          setLoading(false);
          return;
        }
      }
      delete values.verifyPassword;

      const birthDate = new Date(values.birthDate).getTime();
      sdk
        .addUser({
          user: {
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNo: values.phoneNo,
            role: values.role,
            type: values.type,
            birthDate: Number.isNaN(birthDate) ? null : birthDate,
            bankInfo: values.bankName &&
              values.accountNo &&
              values.accountName && {
                bankName: values.bankName,
                accountNo: values.accountNo,
              },
            password: values.password,
          },
        })
        .then(() => {
          getUsers?.();
          message.success("Амжилттай нэмэгдлээ.", 2);
          form.resetFields();
          setLoading(false);
        })
        .catch((err) => {
          message.warn(err.response?.errors[0]?.message, 2);
          setLoading(false);
        })
        .finally(() => setLoading(false));
    },
    [form, getUsers]
  );
  return {
    addUser,
    editUser,
    form,
    loading: loading ?? isLoading,
    userData: data,
    createMSCD: createMSCDMutation.mutate,
  };
}
